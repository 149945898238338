<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>재해유형별통계</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<div class="panel-body" style="width: 1300px; margin: 20px 0px 0px auto">
				<form action="#" @submit.prevent="search" @keyup.enter.prevent="search">
					<div class="row">
						<div class="col-md-2" style="width: 300px">
							<div class="form-group">
								<!-- 현장 -->
								<label>{{ detailsFieldMap.siteId }}</label>
								<select2 v-model="searchVM.siteId" :disabled="isSiteDisabled">
									<option value="">전체</option>
									<option v-for="item in searchOptions.siteOptions" :key="item.siteId" :value="item.siteId">
										{{ item.siteNm }}
									</option>
								</select2>
							</div>
						</div>
						<div class="col-md-2" style="width: 300px; margin-left: -50px">
							<div class="form-group">
								<!-- 업체 -->
								<label>{{ detailsFieldMap.actnCompanyId }}</label>
								<select2 v-model="searchVM.actnCompanyId" :disabled="isCheckedSiteTotal">
									<option value="">전체</option>
									<option v-for="item in searchOptions.companyOptions" :key="item.companyId" :value="item.companyId">
										{{ item.companyNm }}
									</option>
								</select2>
							</div>
						</div>
						<div class="col-md-5" period style="width: 430px; margin-left: -50px">
							<!-- 요청일자 -->
							<div class="form-group" period>
								<label style="margin-right: 10px">{{ detailsFieldMap.period }}</label>
								<input type="date" class="form-control" style="margin-right: 10px" v-model="searchVM.periodStrtDt" :format="'yyyy-MM-dd'" />
								~
								<input
									type="date"
									class="form-control"
									style="margin-left: 10px"
									v-model="searchVM.periodEndDt"
									:format="'yyyy-MM-dd'"
									:min="searchVM.periodStrtDt"
									:max="maxDay"
								/>
							</div>
						</div>
						<!-- 현장단위합계 체크 -->
						<div class="col-md-2" style="width: 200px; margin-left: -30px">
							<div class="form-group" style="margin-bottom: 0px; display: flex; justify-content: center">
								<label style="margin-top: 8px; padding-right: 7px">현장단위합계</label>
								<div class="checkbox">
									<input type="checkbox" id="check" v-model="searchVM.siteTotal" />
								</div>
							</div>
						</div>
						<div class="mb-10 text-right" style="float: right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div>
			<div class="mb-10 text-right">
				<button type="button" class="btn btn-labeled bg-teal" @click="downloadExcel">
					<b><i class="icon-file-excel"></i></b>
					엑셀다운로드
				</button>
			</div>
			<!-- Grid -->
			<KendoGrid
				ref="grid"
				:auto-bind="false"
				:api-url="apiUrl.pageListApi"
				:columns="gridColumns"
				:apply-search-condition="applySearchStateOnGridLoad"
				@selected-row-item-changed="selectedRowItemChanged"
			></KendoGrid>

			<div class="mt-10 mb-15 text-right"></div>
		</div>

		<div id="detailsChart" class="row" v-show="rowSelected">
			<div class="col-lg-12">
				<!-- 1. 일별 API 호출(최근N일) -->
				<div class="panel panel-flat">
					<div class="panel-heading">
						<h6 class="panel-title">
							<i class="icon-stats-growth position-left"></i>
							<b>막대 차트</b>
						</h6>
						<div class="heading-elements">
							<ul class="icons-list">
								<li><a @click="pannelHidden" data-action="collapse"></a></li>
							</ul>
						</div>
					</div>
					<div class="panel-body">
						<div id="columnChart"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import KendoGrid from '@/components/KendoGrid';
import Select2 from '@/components/Select2';
import moment from 'moment';
import _ from 'lodash';
import apiIndex from '../../api/index';
const commUrl = apiIndex.comm;
const accidentStatisticUrl = apiIndex.accidentStatistic;

let axiosExtention;

export default {
	components: {
		KendoGrid,
		select2: Select2,
	},
	data: () => ({
		pageParam: null,
		apiUrl: {
			pageListApi: accidentStatisticUrl.inqAccidentStatistic,
		},
		searchVM: {},
		// 상세 select options
		detailsOptions: {},
		// 검색 select options
		searchOptions: {},
		// 적용된 검색조건
		searchState: {},
		gridColumns: [
			{ field: 'siteNm', title: '현장명', width: '10%' },
			{ field: 'actnCompanyNm', title: '협력업체명', width: '10%' },
			{ field: 'accdtTypeCd01', title: '추락', align: 'right', width: '4.6%' },
			{ field: 'accdtTypeCd02', title: '전도', align: 'right', width: '4.6%' },
			{ field: 'accdtTypeCd03', title: '붕괴/도괴', align: 'right', width: '4.6%' },
			{ field: 'accdtTypeCd04', title: '낙하', align: 'right', width: '4.6%' },
			{ field: 'accdtTypeCd05', title: '비래', align: 'right', width: '4.6%' },
			{ field: 'accdtTypeCd06', title: '충돌', align: 'right', width: '4.6%' },
			{ field: 'accdtTypeCd07', title: '협착', align: 'right', width: '4.6%' },
			{ field: 'accdtTypeCd08', title: '화재/폭발', align: 'right', width: '4.6%' },
			{ field: 'accdtTypeCd09', title: '감전', align: 'right', width: '4.6%' },
			{ field: 'accdtTypeCd10', title: '절단', align: 'right', width: '4.6%' },
			{ field: 'accdtTypeCd11', title: '찔림', align: 'right', width: '4.6%' },
			{ field: 'accdtTypeCd12', title: '끼임', align: 'right', width: '4.6%' },
			{ field: 'accdtTypeCd13', title: '중독', align: 'right', width: '4.6%' },
			{ field: 'accdtTypeCd14', title: '직업병', align: 'right', width: '4.6%' },
			{ field: 'accdtTypeCd15', title: '기타', align: 'right', width: '4.6%' },
		],
		detailsFieldMap: {
			siteId: '현장',
			actnCompanyId: '업체',
			period: '요청일자',
		},
		//상세편집 원본
		detailsItemOriginal: {},
		//상세편집 수정본
		detailsItem: {},
		chartJson: [],
		isSiteDisabled: false,
		// openDetailsForm: false,
		rowSelected: false,
		//periodEndDt의 지정 가능한 최대 일자
		maxDay: '',
	}),
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('accidentStatisticPageParam'));
		if (!this.pageParam) location.href = apiIndex.mainUrl;
		// 본사관리자가 아니면, 현장 select box Disabled
		if (this.pageParam.loginUserRolecd !== '00') {
			this.isSiteDisabled = true;
		}
		this.searchVM = {
			siteId: this.pageParam.loginUserSiteId,
			actnCompanyId: '',
			periodStrtDt: moment().add(-6, 'day').format('YYYY-MM-DD'),
			periodEndDt: moment().format('YYYY-MM-DD'),
			siteTotal: false,
		};
		this.searchOptions = {
			siteOptions: this.pageParam.siteOptions,
			companyOptions: this.pageParam.companyOptions,
		};

		axiosExtention = this.$jarvisExtention.axiosExtention;
	},
	mounted() {
		this.search();
	},
	computed: {
		// API 호출 통계(일별, 월별-주간)
		charData() {
			return {
				// 차트 원본 데이터(json 배열) 세팅
				dataSource: [],
				// 차트 데이터 셋 Defaults 설정
				seriesDefaults: {
					overlay: {
						// Gradation 효과 제거
						gradient: 'none',
					},
				},
				// 차트 데이터 셋 설정
				series: [],
				//X축
				categoryAxis: {
					categories: ['재해 유형'],
					// rotation: 화면이 작아졌을 때 세로로 보여줄 거냐..
					// foramt: 값을 보여줄 형태 지정
					// step: 값이 몇 씩 증가하는가..
					labels: { rotation: 'auto' },
				},
				//Y축
				valueAxis: [
					{
						// format: y축 데이터를 어떻게 표시할 것인가..
						majorUnit: 1,
					},
				],
				//툴팁( 마우스 올렸을 때 나오는 조그만 정보 창 )
				tooltip: {
					// 툴팁 표시, 비표시 설정
					visible: true,
					// 툴팁 내용을 어떻게 표시할 것인가..
					template: `#: category #<br/>#: series.name # : #: kendo.toString(value, 'n0') #건`,
				},
			};
		},
		// 현장단위합계가 체크 되어있는지 확인
		isCheckedSiteTotal() {
			if (this.searchVM.siteTotal == true) {
				return true;
			} else {
				return false;
			}
		},
	},
	watch: {
		// chartJson(newVal) {
		chartJson() {
			//details활성화 시 스크롤 이동처리
			this.$nextTick(function () {
				window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
			});
		},
		'searchVM.siteId': {
			handler(value, oldValue) {
				if (oldValue !== null) {
					this.$axios
						.post(commUrl.inqCompanyFindBySiteId, { siteId: this.searchVM.siteId })
						.then(
							function (r) {
								this.searchOptions.companyOptions = r.data;
								this.searchVM.actnCompanyId = '';
							}.bind(this),
						)
						.catch(axiosExtention.buildErrorHandler());
				}
			},
		},
		'searchVM.siteTotal': {
			handler(value) {
				if (value == true) {
					this.searchVM.actnCompanyId = '';
				}
			},
		},
		//조회기간을 최대 x개월 간으로 하기 위해
		'searchVM.periodStrtDt': {
			handler(value) {
				this.maxDay = moment(value).add(process.env.VUE_APP_MAX_MONTH, 'month').format('YYYY-MM-DD');
			},
		},
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('detailsChart');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		//유효한 요청일자 기간인지 확인
		isValidPeriod() {
			if (
				this.searchVM.periodStrtDt == null ||
				this.searchVM.periodStrtDt == '' ||
				this.searchVM.periodEndDt == null ||
				this.searchVM.periodEndDt == ''
			) {
				alert('날짜를 입력해주세요');
				return false;
			}
			if (new Date(this.searchVM.periodStrtDt) > new Date(this.searchVM.periodEndDt)) {
				alert('유효한 날짜를 입력해주세요');
				return false;
			}
			return true;
		},
		checkSiteTotalStatus() {
			if (this.searchVM.siteTotal == true && this.searchVM.siteId != '' && this.searchVM.siteId != null) {
				//현장단위합계에 체크가 되어있으면서 siteId가 전체가 아닐 때 차트 데이터를 세팅
				this.setChartData({
					siteId: this.searchVM.siteId,
					actnCompanyId: null,
					periodStrtDt: this.searchVM.periodStrtDt,
					periodEndDt: this.searchVM.periodEndDt,
				});
			} else {
				this.rowSelected = false;
			}
		},
		// 모범사례 검색 적용 처리
		search() {
			this.searchState = $.extend(true /*deep*/, {}, this.searchVM);
			if (this.isValidPeriod() == false) {
				return;
			}
			this.checkSiteTotalStatus();
			this.loadGrid();
		},
		// 모범사례 그리드 리스트 불러오기
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		// 모범사례 그리드 로드 시 검색조건 적용
		applySearchStateOnGridLoad(param) {
			return $.extend(true /*deep*/, param, this.searchState || {});
		},
		// 모범사례 엑셀 다운로드
		downloadExcel() {
			this.$axios({
				url: accidentStatisticUrl.exlAccidentStatistic,
				method: 'POST',
				data: this.searchState,
				responseType: 'blob', // important
			})
				.then(response => {
					let date = new Date();
					// 저장 파일 명 생성
					let fileName =
						'재해유형별통계_' + date.getFullYear() + ('0' + (1 + date.getMonth())).slice(-2) + ('0' + date.getDate()).slice(-2) + '.xlsx';
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 모범사례 상세화면 닫기
		closeDetails() {
			this.detailsItemOriginal = {};
			this.detailsItem = {};
			//this.openDetailsForm = false;
			window.scrollTo({ top: 50, behavior: 'smooth' });
		},
		//charData세팅하기
		setChartData(mapData) {
			this.$axios
				.post(accidentStatisticUrl.inqOneAccidentStatistic, mapData)
				.then(
					function (response) {
						this.chartJson = response.data;
						this.rowSelected = true;
						this.loadChartData();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
		// 모범사례 그리드 행 선택 콜백
		selectedRowItemChanged(selectedRowItem) {
			if (!_.isEmpty(selectedRowItem)) {
				this.setChartData({
					siteId: selectedRowItem.siteId,
					actnCompanyId: selectedRowItem.actnCompanyId,
					periodStrtDt: this.searchVM.periodStrtDt,
					periodEndDt: this.searchVM.periodEndDt,
				});
				//수정시작
				// this.$axios
				// 	.post(accidentStatisticUrl.inqOneAccidentStatistic, {
				// 		siteId: selectedRowItem.siteId,
				// 		actnCompanyId: selectedRowItem.actnCompanyId,
				// 		periodStrtDt: this.searchVM.periodStrtDt,
				// 		periodEndDt: this.searchVM.periodEndDt,
				// 	})
				// 	.then(
				// 		function (response) {
				// 			this.chartJson = response.data;
				// 			// this.openDetailsForm = true;
				// 			this.rowSelected = true;
				// 			this.loadChartData();
				// 		}.bind(this),
				// 	)
				// 	.catch(axiosExtention.buildErrorHandler());
			} //상세화면 닫기 (다시로드등으로 선택변경)
			else {
				this.closeDetails();
				this.rowSelected = false;
			}
		},
		loadChartData() {
			this.charData.dataSource = { data: this.chartJson };

			this.charData.series = [
				{ name: '추락', field: 'accdtTypeCd01', type: 'column', color: '#428bca' },
				{ name: '전도', field: 'accdtTypeCd02', type: 'column', color: '#5bc0de' },
				{ name: '붕괴/도괴', field: 'accdtTypeCd03', type: 'column', color: '#5cb85c' },
				{ name: '낙하', field: 'accdtTypeCd04', type: 'column', color: '#f2b661' },
				{ name: '비래', field: 'accdtTypeCd05', type: 'column', color: '#e67d4a' },
				{ name: '충돌', field: 'accdtTypeCd06', type: 'column', color: '#da3b36' },
				{ name: '협착', field: 'accdtTypeCd07', type: 'column', color: '#428bca' },
				{ name: '화재/폭발', field: 'accdtTypeCd08', type: 'column', color: '#5bc0de' },
				{ name: '감전', field: 'accdtTypeCd09', type: 'column', color: '#5cb85c' },
				{ name: '절단', field: 'accdtTypeCd10', type: 'column', color: '#f2b661' },
				{ name: '찔림', field: 'accdtTypeCd11', type: 'column', color: '#e67d4a' },
				{ name: '끼임', field: 'accdtTypeCd12', type: 'column', color: '#da3b36' },
				{ name: '중독', field: 'accdtTypeCd13', type: 'column', color: '#428bca' },
				{ name: '직업병', field: 'accdtTypeCd14', type: 'column', color: '#5bc0de' },
				{ name: '기타', field: 'accdtTypeCd15', type: 'column', color: '#5cb85c' },
			];

			this.drawChart();
		},
		drawChart() {
			$('#columnChart').kendoChart({
				chartArea: { width: this.$refs.grid.$el.clientWidth - 50 },
				dataSource: this.charData.dataSource,
				categoryAxis: this.charData.categoryAxis,
				valueAxis: this.charData.valueAxis,
				seriesDefaults: this.charData.seriesDefaults,
				series: this.charData.series,
				legend: {
					position: 'bottom',
				},
				tooltip: this.charData.tooltip,
			});
		},
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
	},
};
</script>

<style>
span.select2-selection.select2-selection--single[aria-disabled='true'] {
	background: #eee;
}
.checkbox {
	display: inline-block !important;
	margin-left: 0.7em;
}

.row .form-group > span {
	width: 60% !important;
	margin-left: 10px;
}
.form-group input {
	display: inline-block;
}

#detailsForm > .panel > .panel-heading > div {
	display: flex;
	margin-bottom: 15px;
}
#detailsForm > .panel > .panel-heading > div > div {
	display: flex;
	align-items: center;
}
#detailsForm > .panel > .panel-heading > div > div > * {
	margin-right: 5px;
}
#detailsForm > .panel > .panel-heading span {
	margin-right: 10px;
}

#detailsForm > .panel > .panel-body > div {
	width: calc(50% - 10px);
	display: inline-flex;
	flex-direction: column;
}
#detailsForm > .panel > .panel-body > div:last-child {
	float: right;
}

#detailsForm .panel-heading {
	font-weight: bold;
}
#detailsForm .panel-heading input {
	max-width: 160px;
}

#detailsForm .law-panel .panel-body textarea {
	height: 200px;
	resize: none;
	margin-bottom: 15px;
}
#detailsForm .law-panel .btn-wrapper {
	padding: 0;
	margin-bottom: 15px;
}
#detailsForm .law-panel .btn-wrapper .btn {
	width: calc(50% - 5px);
	margin: 0;
}
#detailsForm .law-panel .btn-wrapper .btn:last-child {
	float: right;
}
#detailsForm tr.active td {
	background: #ddd;
}

/* #detailsForm .panel-body .panel-flat { max-height:100px; } */
#detailsForm .panel-body .panel-flat .panel-body {
	display: flex;
	flex-direction: column;
}

#detailsForm .table-wrapper {
	max-height: 200px;
	overflow-y: auto;
}
.modal .table-wrapper {
	max-height: 300px;
	overflow-y: auto;
}
.modal .table tbody tr:hover {
	cursor: pointer;
}

.table th {
	background: #eee;
	vertical-align: middle !important;
	font-weight: bold;
}
.panel-body .table th:first-child {
	width: 120px;
	text-align: center;
}

.table td {
	vertical-align: middle;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 1px;
}
.panel-body .table td:first-child {
	text-align: center;
}
.panel-body .table td:last-child {
	text-align: center;
	width: 80px;
}

.table [empty] {
	display: none;
}
.table [empty] td {
	padding: 30px;
	font-size: 1.2em;
}
.table [empty]:first-child:last-child {
	display: table-row;
}

.btn-wrapper {
	width: 100%;
	clear: both;
	padding-bottom: 30px;
	padding-right: 20px;
}
.btn-wrapper .btn {
	margin-left: 10px;
}

.modal .search {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}
[type='search'] {
	display: inline-block;
	width: 180px;
	margin-right: 5px;
}

[period] [type='date'] {
	width: 150px !important;
}

.col-lg-12 {
	padding-right: 0 !important;
}
</style>
